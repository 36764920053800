import React, { useContext } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { UserContext } from "../../contexts/UserContext";

//views

import Login from "../Views/Login/Login";
import LeadsCompra from "../Views/LeadsCompra/LeadsCompra";


const AppRouter = () => {
  const { user } = useContext(UserContext);

  return (
    <Switch>
      <Route exact path="/">
        {user ? <Redirect to="/leads-compra" /> : <Login />}
      </Route>

      <Route exact path="/leads-compra">
        {!user ? <Redirect to="/" /> : <LeadsCompra />}
      </Route>
      
    </Switch>
  );
};

export default AppRouter