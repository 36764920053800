import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
import { useUser } from "../../../hooks/useUser";
import { login } from "../../../services/AuthService";
import { setAccessToken } from "../../../store/AccessTokenStore";
import "./Login.css";

const Login = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { push } = useHistory();
  const { getUser: doLogin } = useUser();
  const [authError, setAuthError] = useState(null);

  const onSubmit = (data) => {
    setAuthError(null);
    login(data)
      .then((response) => {
       
        setAccessToken(response.token.split(' ')[1]);
        doLogin().then(() => push("/leads-compra"));
      })
      .catch((error) => setAuthError("auth"));
  };
  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-4 col-md-5 col-sm-6 col-8">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group controlId="formBasicEmail">
              <Form.Control
                className={(errors.email || authError) && "is-invalid"}
                type="email"
                placeholder="email"
                {...register("email", { required: true })}
              />
              {errors.email && (
                <div className="invalid-feedback">
                  <p>Introduzca el email</p>
                </div>
              )}
            </Form.Group>
            <Form.Group controlId="formBasicPassword">
              <Form.Control
                className={(errors.password || authError) && "is-invalid"}
                type="password"
                placeholder="Contraseña"
                {...register("password", { required: true })}
              />

              {errors.password && (
                <div className="invalid-feedback">
                  <div className="invalid-feedback">
                    {" "}
                    <p>Introduzca la contraseña</p>
                  </div>
                </div>
              )}
              {authError === "auth" && !errors.password && (
                <div className="invalid-feedback">
                  {" "}
                  <p>Contraseña incorrecta</p>
                </div>
              )}
            </Form.Group>

            <Button className="custom__button" type="submit">
              Acceder
            </Button>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Login;
