

import { getAccessToken, logout } from "../store/AccessTokenStore";
import { create } from "./BaseService";
const http = create({
  baseURL: process.env.REACT_APP_API_HOST || `http://localhost:3002`,
  maxContentLength: Infinity,
  maxBodyLength: Infinity,
  timeout: 25000,
  useAccessToken: false,
  reloadOnUnauthorized: false,
})
export const postLead = async (route, data) => {

  try {
    const response = await http.post(route, data, {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    });
    return response.data;
  } catch (e) {
    throw e;
  }
};
export const getProvincias = async (route) => {
  try {
    const response = await http.get(route, {
      headers: { Authorization: `Bearer ${getAccessToken()}` },
      params: {
        /* limit: 20, offset: offset */
      },
    });
    return response;
  } catch (e) {
    throw e;
  }
};


