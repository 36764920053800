import { create } from "./BaseService";
import { getAccessToken, logout } from "../store/AccessTokenStore";
const http = create({
    baseURL: process.env.REACT_APP_API_HOST || `http://localhost:3002`,
    maxContentLength: Infinity,
    maxBodyLength: Infinity,
    timeout: 25000,
    useAccessToken: false,
    reloadOnUnauthorized: false,
})

export const jato = (query) => {
    console.log(query)
    return http.post('filters/jato', {query:query}, {
        headers: {
            Authorization: `Bearer ${getAccessToken()}`,
        },
    })
}