import React, { useState, useEffect, useRef } from "react";
import FilterV from "../FilterAPIjato"
import styles from "./index.module.scss";

export default ({ vehiculo, setVehiculo, state, className }) => {


    const getDataAdd = (filter, value) => {

        switch (filter) {
            case 'marca_vehiculo':
                setVehiculo({
                    ...vehiculo,
                    [filter]: value,
                    modelo: "",
                    matriculacion: "",
                    combustible: "",
                    ccambios: "",
                    carroceria: "",
                    version: "",
                });
                break;
            case 'modelo':
                setVehiculo({
                    ...vehiculo,
                    [filter]: value,
                    matriculacion: "",
                    combustible: "",
                    ccambios: "",
                    carroceria: "",
                    version: "",
                });
                break;
            case 'matriculacion':
                setVehiculo({
                    ...vehiculo,
                    [filter]: value,
                    combustible: "",
                    ccambios: "",
                    carroceria: "",
                    version: "",
                });
                break;
            case 'combustible':
                setVehiculo({
                    ...vehiculo,
                    [filter]: value,
                    ccambios: "",
                    carroceria: "",
                    version: "",
                });
                break;
            case 'ccambios':
                setVehiculo({
                    ...vehiculo,
                    [filter]: value,
                    carroceria: "",
                    version: "",
                });
                break;
            case 'carroceria':
                setVehiculo({
                    ...vehiculo,
                    [filter]: value,
                    version: "",
                });
                break;
            case 'version':
                setVehiculo({
                    ...vehiculo,
                    [filter]: value,

                });
                break;

        }


    };



    return (

        <>
            <div className="row" style={{ marginTop: ".25rem" }}>
                <div className={`col-md-4 col-sm-12 ${!className ? styles.floatingLabel : "none"}`}>
                    <label className={vehiculo?.marca_vehiculo ? styles.select : styles.notSelect} >Marca: </label>
                    <div className={styles.filter}>
                        <FilterV
                            vehiculo={vehiculo}
                            setVehiculo={setVehiculo}
                            type={"brand"}
                            key={"Marca del Vehiculo"}
                            setter={"marca_vehiculo"}
                            sendData={getDataAdd}
                            name={"Marca del Vehiculo*"}
                            load={true}
                            disabled={false}
                            query={"brands"}
                            className={!className ? ` ${styles.outline}` : ''}
                            parentValues={vehiculo?.marca_vehiculo}
                        />
                    </div>


                </div>

                <div className={`col-md-4 col-sm-12 ${!className ? styles.floatingLabel : "none"}`}>
                    <label className={vehiculo?.modelo ? styles.select : styles.notSelect} >Modelo:</label>
                    <div className={styles.filter}>
                        <FilterV
                            vehiculo={vehiculo}
                            setVehiculo={setVehiculo}
                            key={"Modelo Vehiculo*"}
                            setter={"modelo"}
                            disabled={!vehiculo.marca_vehiculo ? true : false}
                            sendData={getDataAdd}
                            parentValues={vehiculo?.modelo}
                            name={"Modelo"}
                            load={vehiculo.marca_vehiculo ? true : false}
                            query={vehiculo.marca_vehiculo ? `models?brand=${encodeURIComponent(vehiculo.marca_vehiculo[0].value)}` : `models?brand=empty`}
                            className={!className ? !vehiculo.marca_vehiculo ? ` ${styles.disabled} ` : ` ${styles.outline}` : "none"}
                        /></div>
                </div>
                <div className={`col-md-4 col-sm-12 ${!className ? styles.floatingLabel : "none"}`}>
                    <label className={vehiculo?.matriculacion ? styles.select : styles.notSelect} >Año:</label>
                    <div className={styles.filter}>
                        <FilterV
                            vehiculo={vehiculo}
                            setVehiculo={setVehiculo}
                            parentValues={vehiculo?.matriculacion}
                            key={"Año"}
                            setter={"matriculacion"}
                            disabled={!vehiculo.modelo ? true : false}
                            sendData={getDataAdd}
                            name={"Año"}
                            load={vehiculo.modelo ? true : false}
                            query={vehiculo.modelo && vehiculo.modelo[0] ? `years?brand=${encodeURIComponent(vehiculo.marca_vehiculo[0].value)}&model=${vehiculo.modelo[0].value}` : `models?brand=empty`}
                            className={!className ? !vehiculo.modelo ? ` ${styles.disabled} ` : ` ${styles.outline}` : "none"}
                        />
                    </div>
                </div>
            </div>
            <div className="row" style={{ marginTop: ".25rem" }}>

                <div className={`col-md-6 col-sm-12 ${!className ? styles.floatingLabel : "none"}`}>
                    <label className={vehiculo?.combustible ? styles.select : styles.notSelect} >Combustible:</label>
                    <div className={styles.filter}>
                        <FilterV
                            vehiculo={vehiculo}
                            setVehiculo={setVehiculo}
                            key={"Combustible"}
                            setter={"combustible"}
                            disabled={!vehiculo.matriculacion ? true : false}
                            parentValues={vehiculo?.combustible}
                            sendData={getDataAdd}
                            name={"Combustible"}
                            load={vehiculo.matriculacion ? true : false}
                            query={vehiculo.matriculacion && vehiculo.matriculacion[0] ? `fuels?brand=${encodeURIComponent(vehiculo.marca_vehiculo[0]?.value)}&model=${vehiculo.modelo[0]?.value}&year=${vehiculo.matriculacion[0].label}` : `models?brand=empty`}
                            className={!className ? !vehiculo.matriculacion ? ` ${styles.disabled} ` : ` ${styles.outline}` : "none"}
                        />
                    </div>

                </div>
                <div className={`col-md-6 col-sm-12 ${!className ? styles.floatingLabel : "none"}`}>
                    <label className={vehiculo?.ccambios ? styles.select : styles.notSelect} >Caja de cambios:</label>
                    <div className={styles.filter}>
                        <FilterV
                            parentValues={vehiculo?.ccambios}
                            vehiculo={vehiculo}
                            setVehiculo={setVehiculo}
                            key={"Caja de Cambios"}
                            setter={"ccambios"}
                            sendData={getDataAdd}
                            disabled={!vehiculo.combustible ? true : false}
                            name={"Caja de Cambios"}
                            load={vehiculo.combustible ? true : false}
                            query={vehiculo.combustible && vehiculo.combustible[0] ? `gearboxes?brand=${encodeURIComponent(vehiculo.marca_vehiculo[0].value)}&model=${vehiculo.modelo[0].value}&year=${vehiculo.matriculacion[0].label}&fuel=${vehiculo.combustible[0].value}` : `models?brand=empty`}
                            className={!className ? !vehiculo.combustible ? ` ${styles.disabled} ` : ` ${styles.outline}` : "none"}
                        />
                    </div>
                </div>

            </div>
            <div className="row" style={{ marginTop: ".25rem" }}>
                <div className={`col-md-6 col-sm-12 ${!className ? styles.floatingLabel : "none"}`}>
                    <label className={vehiculo?.carroceria ? styles.select : styles.notSelect} >Carrocería:</label>
                    <div className={styles.filter}>
                        <FilterV
                            parentValues={vehiculo?.carroceria}
                            vehiculo={vehiculo}
                            setVehiculo={setVehiculo}
                            key={"Carrocería"}
                            setter={"carroceria"}
                            sendData={getDataAdd}
                            disabled={!vehiculo.ccambios ? true : false}
                            name={"Carroceria"}
                            load={vehiculo.ccambios ? true : false}
                            query={vehiculo.ccambios && vehiculo.ccambios[0] ? `bodies?brand=${encodeURIComponent(vehiculo.marca_vehiculo[0].value)}&model=${vehiculo.modelo[0].value}&year=${vehiculo.matriculacion[0].label}&fuel=${vehiculo.combustible[0].value}&gearbox=${vehiculo.ccambios[0].value}` : `models?brand=empty`}
                            className={!className ? !vehiculo.ccambios ? ` ${styles.disabled} ` : ` ${styles.outline}` : "none"}
                        /></div>
                </div>
                <div className={`col-md-6 col-sm-12 ${!className ? styles.floatingLabel : "none"}`}>
                    <label className={!className ? vehiculo?.version ? styles.select : styles.notSelect : "none"} >Version:</label>
                    <div className={!className ? styles.filter : "none"}>
                        <FilterV
                            vehiculo={vehiculo}
                            setVehiculo={setVehiculo}
                            key={"Versión"}
                            setter={"version"}
                            sendData={getDataAdd}
                            disabled={!vehiculo.carroceria ?? true}
                            name={"Version"}
                            parentValues={vehiculo?.version}
                            load={vehiculo.carroceria ? true : false}
                            query={vehiculo.carroceria && vehiculo.carroceria[0] ? `versions?brand=${encodeURIComponent(vehiculo.marca_vehiculo[0].value)}&model=${vehiculo.modelo[0].value}&year=${vehiculo.matriculacion[0].label}&fuel=${vehiculo.combustible[0].value}&gearbox=${vehiculo.ccambios[0].value}&bodyType=${vehiculo.carroceria[0].value}` : null}
                            className={!className ? !vehiculo.carroceria ? ` ${styles.disabled} ` : ` ${styles.outline}` : "none"}
                        /></div>
                </div>

            </div></>

    );
};