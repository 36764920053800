import React, { useContext } from "react";

import "./CustomNavbar.css";
//components
import { Navbar, Container, Button, Nav } from "react-bootstrap";
import { UserContext } from "../../contexts/UserContext";

import { logout } from "../../store/AccessTokenStore";
import icon from "./logo-flexicar.svg"
const CustomNavbar = () => {
  const { user } = useContext(UserContext);

  return (
    <Navbar bg="light">
      <Container>
        <Navbar.Brand href="/">
          <img
            alt=""
            src={icon}
            width="200"
            className="d-inline-block align-top"
          />{" "}
        </Navbar.Brand>
        {user && (
          <Nav>
            <Button onClick={logout}>Cerrar sesión</Button>
          </Nav>
        )}
      </Container>
    </Navbar>
  );
};

export default CustomNavbar;
