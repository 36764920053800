import React, { useState, useEffect } from "react";
import Select from "react-dropdown-select";
import { get } from "../../services/axiosCalls";

import errorCall from "../../services/errorCall";
import { jato } from "../../services/JatoService";
export default ({
  disabled,
  query,
  name,
  multi,
  sendData,
  parentValues,
  setter,
  load,
  vehiculo,
  setVehiculo,
  className,
}) => {
  const [options, setOptions] = useState([]);
  const [filterValues, setFilterValues] = useState([]);

  useEffect(() => {
    const launchQuery = async () => {
      try {
        const response = await jato(query)
        console.log(response)
        console.log(vehiculo)
        if (!response[0]) {
         console.log(response, 'ñe')
          if (query.startsWith("models")) {
            console.log('hola')
            setVehiculo({
              ...vehiculo,
              modelo: "",
              matriculacion: "",
              combustible: "",
              ccambios: "",
              carroceria: "",
              version: "",
            });
          }
          if (query.startsWith("years")) {
            setVehiculo({
              ...vehiculo,
              modelo: "",
              matriculacion: "",
              combustible: "",
              ccambios: "",
              carroceria: "",
              version: "",
            });
          }
          if (query.startsWith("fuels")) {
            setVehiculo({
              ...vehiculo,
              matriculacion: "",
              combustible: "",
              ccambios: "",
              carroceria: "",
              version: "",
            });
          }
          if (query.startsWith("gearboxes")) {
            setVehiculo({
              ...vehiculo,
              combustible: "",
              ccambios: "",
              carroceria: "",
              version: "",
            });
          }
          if (query.startsWith("bodies")) {
            setVehiculo({
              ...vehiculo,
              ccambios: "",
              carroceria: "",
              version: "",
            });
          }
        }
        if (!response[0].year ) {
          await setOptions(response);
        }

       else {
          const brands = await response.map((response) =>
            Object.values(response)
          );

          await setOptions(brands);
        }
      } catch (e) {
        errorCall(e);
      }
    };
    if (load) {
      launchQuery();
    }
  }, [query]);

  useEffect(() => {
    if (parentValues && filterValues) {
      if (parentValues?.length === 0) {
        setFilterValues([]);
      }

      if (
        (parentValues[0] &&
          filterValues[0] &&
          parentValues[0].label !== filterValues[0].label) ||
        vehiculo?.marca_vehiculo
      ) {
        setFilterValues(parentValues);
      }
    } else {
      setFilterValues([]);
    }
  }, [parentValues]);

  const onChange = (value) => {
    sendData(setter ? setter : name, value);
    setFilterValues(value);
  };
  let versionYear
  // if (options?.modelYear) {
  //   versionYear=`${x.name} - ${x.modelYear} - ${x.doors}`
  // }
  return (
    <div>
      {options !== null ? (
        <React.Fragment>
          <Select
            color={"#FF5D0E"}
            options={options.map((x, index) => {
              return {
                label: x.name && !x.modelYear ? x.name : x.modelYear ? x.name.concat(' - ', x.doors, ' ', 'P', ' ', '(', x.modelYear, ')')  :x.version ? x.version :  x[0],
                value: x.code ? x.code : x.id ? x.id : x.model ? x.model: x.brand ? x.brand : index,
              };
            })}
            placeholder={name}
            values={filterValues}
            multi={multi}
            disabled={disabled}
            noDataRenderer={() => (
              <span style={{ textAlign: "center" }}>Sin datos</span>
            )}
            onChange={(value) => onChange(value)}
            className={`fix_selector_arrow ${className}`}
            
          />
        </React.Fragment>
      ) : (
        <React.Fragment></React.Fragment>
      )}
    </div>
  );
};
