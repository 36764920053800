import "./App.css";
import "./styles/global.scss";
//components
import AppRouter from "./components/AppRouter/AppRouter";
import CustomNavbar from "./components/CustomNavbar/CustomNavbar";

function App() {
  return (
    <div className="App">
      <header className="mb-5">
        <CustomNavbar />
        
      </header>

      <main>
        <AppRouter />
      </main>
    </div>
  );
}

export default App;
