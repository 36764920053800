import React, { useState, useEffect } from "react";

import "bootstrap/dist/css/bootstrap.min.css";

import { Container, Row, Col, Button } from "reactstrap";
import MaterialIcon from "material-icons-react";
import Select from "react-select";
import Checkbox from "../Checkbox";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { postLead, getProvincias } from "../../services/LeadsService";
import { get, post } from "../../services/axiosCalls";
import errorCall from "../../services/errorCall";
import { errorToast, successToast } from "../../helpers/toastFunctions";
import DatosVehiculo from "../DatosVehiculos";

import styles from "./index.module.scss";
import { useHistory } from "react-router";
const FormLead = () => {
  const [provincias, setProvincias] = useState(null);
  const [lead, setLead] = useState({
    apellido1: null,
    apellido2: null,
    bastidor: null,
    buscado: null,
    comentarios: null,
    condiciones: true,
    email: null,
    itv: null,
    km: null,
    matricula: null,
    municipio: null,
    nombre: null,
    telefono1: null,
    telefono2: null,
    provincia: null,
    comentarios: null,
    importado: 0,
  });
  const [vehiculo, setVehiculo] = useState({
    marca_vehiculo: "",
    modelo: "",
    matriculacion: "",
    combustible: "",
    ccambios: "",
    carroceria: "",
    version: "",
  });
  useEffect(() => {
    getProvincias(`/filters`)
      .then((response) => {
        setProvincias(response);
      })
      .catch((e) => console.log(e));
  }, []);

  const handleValues = (e, field, type) => {
    const val = e.target.value;
    setLead({
      ...lead,
      [field]: val,
    });
  };
  const getData_CheckBox = (value, filter) => {
    setLead({
      ...lead,
      [filter]: value,
    });
  };

  const createLead = async () => {
    if (lead.nombre === "" || lead.nombre === null) {
      return errorToast(<span>El campo nombre es obligatorio</span>);
    }
    if (
      lead.telefono1 === "" ||
      lead.telefono1 === null ||
      lead.telefono1.length != 9
    ) {
      return errorToast(<span>Introduce un telefono correcto</span>);
    }
    if (lead.email === "" || lead.email === null) {
      return errorToast(<span>El campo email es obligatorio</span>);
    }
    if (vehiculo.marca_vehiculo === "" || vehiculo.marca_vehiculo === null) {
      return errorToast(<span>Seleccione la marca del vehículo</span>);
    }
    if (vehiculo.modelo === "" || vehiculo.modelo === null) {
      return errorToast(<span>Seleccione un modelo</span>);
    }

    try {
      const data = {
        client: {
          nombre: lead.nombre,
          apellido1: lead.apellido1,
          apellido2: lead.apellido2,
          telefono1: lead.telefono1,
          telefono2: lead.telefono2,
          email: lead.email,
          provincia: lead?.provincia?.label,
          id_provincia: lead?.provincia?.value,
          poblacion: lead.municipio,
        },
        lead: {
          marca_name: vehiculo.marca_vehiculo[0].label,
          marca: vehiculo.marca_vehiculo[0].value,
          modelo_name: vehiculo.modelo[0].label,
          modelo: vehiculo.modelo[0].value,
          version: vehiculo?.version[0] ? vehiculo?.version[0].label : null,
          anno: vehiculo?.matriculacion[0]
            ? vehiculo?.matriculacion[0].label
            : null,
          importado: lead.importado,
          id_tipo_combustible: vehiculo?.combustible[0]
            ? vehiculo?.combustible[0].value
            : null,
          id_tipo_cajacambios: vehiculo?.ccambios[0]
            ? vehiculo?.ccambios[0].value
            : null,
          id_tipo_carroceria: vehiculo?.carroceria[0]
            ? vehiculo?.carroceria[0].value
            : null,
          id_jato: vehiculo?.version[0] ? vehiculo?.version[0].value : null,
          ultima_itv: lead.itv,
          km: lead.km,
          matricula: lead.matricula,
          bastidor: lead.bastidor,
          tasacion_precio_buscado_cliente: lead.buscado,
          anotaciones: lead.comentarios,
        },
      };
      console.log(data);
      postLead(`/leads`, data)
        .then((response) => {
          successToast("Registro enviado");
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        })

        .catch((e) => console.log(e));
    } catch (e) {
      console.log(e);
      errorCall(e);
    }
  };

  return (
    <>
      <ToastContainer />
      <Container className="mt-5 mb-5">
        <div
          className="clearfix mb-3"
          style={{
            padding: ".7rem",
            backgroundColor: "#2C2C2C",
            display: "flex",
            alignItems: "center",
            marginTop: "1rem",
            justifyContent: "space-between",
          }}
        >
          <span className="tittle ml-4 mr-auto p-2">Datos de Contacto</span>
        </div>

        <div className="row">
          <div className="col-md-4 col-sm-12 ">
            <div className={styles.floatingLabel}>
              <input
                id="Nombre"
                placeholder="Nombre *"
                className={`form-control ds-input mt-1 ${styles.floatingInput}`}
                type="text"
                value={lead?.nombre}
                onChange={(e) => handleValues(e, "nombre", "lead")}
              />
              <label for="Nombre">Nombre:</label>
            </div>
          </div>
          <div className="col-md-4 col-sm-12">
            <div className={styles.floatingLabel}>
              <input
                id="Apellido1"
                placeholder={"Primer apellido"}
                className={`form-control ds-input mt-1 ${styles.floatingInput}`}
                type="text"
                value={lead?.apellido1}
                onChange={(e) => handleValues(e, "apellido1", "lead")}
              />
              <label for="Apellido1">Primer Apellido:</label>
            </div>
          </div>
          <div className="col-md-4 col-sm-12">
            <div className={styles.floatingLabel}>
              <input
                placeholder={"Segundo apellido"}
                className={`form-control ds-input mt-1 ${styles.floatingInput}`}
                type="text"
                value={lead?.apellido2}
                onChange={(e) => handleValues(e, "apellido2", "lead")}
              />
              <label for="Apellido1">Segundo Apellido:</label>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <div className={styles.floatingLabel}>
              <input
                placeholder={"Teléfono principal *"}
                className={`form-control ds-input mt-1 ${styles.floatingInput}`}
                type="text"
                value={lead?.telefono1}
                onChange={(e) => handleValues(e, "telefono1", "lead")}
              />
              <label for="telefono">Teléfono Principal:</label>
            </div>
          </div>
          <div className="col-md-6 col-sm-12">
            <div className={styles.floatingLabel}>
              <input
                placeholder={"Teléfono secundario"}
                className={`form-control ds-input mt-1 ${styles.floatingInput}`}
                type="text"
                value={lead?.telefono2}
                onChange={(e) => handleValues(e, "telefono2", "lead")}
              />
              <label for="telefono">Teléfono Secundario:</label>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 col-sm-12">
            <div className={styles.floatingLabel}>
              <input
                placeholder={"Email*"}
                className={`form-control ds-input mt-1 ${styles.floatingInput}`}
                value={lead?.email}
                onChange={(e) => handleValues(e, "email", "lead")}
              />
              <label for="telefono">Email:</label>
            </div>
          </div>
        </div>

        <div className="row">
          <div className={`col-md-3 col-sm-12 ${styles.floatingLabel}`}>
            <label
              className={lead?.provincia ? styles.select : styles.notSelect}
            >
              Provincia:
            </label>
            <div className={styles.filter}>
              <Select
                placeholder={"Provincia"}
                options={provincias}
                className={` ${styles.outline}`}
                value={lead?.provincia}
                onChange={(e) => setLead({ ...lead, provincia: e })}
              />
            </div>
          </div>
          <div className="col-md-9 col-sm-12">
            <div className={styles.floatingLabel}>
              <input
                placeholder={"Municipio"}
                className="form-control ds-input mt-1"
                type="text"
                className={`form-control ds-input mt-1 ${styles.floatingInput}`}
                value={lead?.municipio}
                onChange={(e) => handleValues(e, "municipio", "lead")}
              />

              <label for="telefono">Municipio:</label>
            </div>
          </div>
        </div>

        <div className="row">
          <Checkbox
            key={"Condiciones"}
            name={"He leído y acepto los términos y condiciones"}
            status={"checked"}
            onChange={() => getData_CheckBox(!lead.condiciones, "condiciones")}
          />
        </div>

        <div
          className="clearfix mb-3"
          style={{
            padding: ".7rem",
            backgroundColor: "#2C2C2C",
            display: "flex",
            alignItems: "center",
            marginTop: "1rem",
          }}
        >
          <span className="tittle ml-4">Datos del Vehículo</span>
        </div>
        <div className="row">
          <div className={"col-md-12 col-sm-12"}>
            <div>
              <DatosVehiculo vehiculo={vehiculo} setVehiculo={setVehiculo} />
            </div>
            <div className="row">
              <div className="col-md-3 col-sm-12">
                <div className={styles.floatingLabel}>
                  <input
                    placeholder={"Kilómetros"}
                    className={`form-control ds-input mt-1 ${styles.floatingInput}`}
                    type="number"
                    value={lead?.kilometros}
                    onChange={(e) => handleValues(e, "km", "lead")}
                  />
                  <label for="telefono">Kilómetros:</label>
                </div>
              </div>

              <div className="col-md-3 col-sm-12">
                <div className={styles.floatingLabel}>
                  <input
                    placeholder={"Matrícula"}
                    className={`form-control ds-input mt-1 ${styles.floatingInput}`}
                    type="text"
                    value={lead?.matricula}
                    onChange={(e) => handleValues(e, "matricula", "lead")}
                  />
                  <label for="telefono">Matrícula:</label>
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <div className={styles.floatingLabel}>
                  <input
                    placeholder={"Bastidor"}
                    className={`form-control ds-input mt-1 ${styles.floatingInput}`}
                    type="text"
                    value={lead?.bastidor}
                    onChange={(e) => handleValues(e, "bastidor", "lead")}
                  />
                  <label for="telefono">Bastidor:</label>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 col-sm-12">
                <div className={styles.floatingLabel}>
                  <input
                    placeholder={"Precio buscado por el cliente"}
                    className={`form-control ds-input mt-1 ${styles.floatingInput}`}
                    type="text"
                    value={lead?.buscado}
                    onChange={(e) => handleValues(e, "buscado", "lead")}
                  />
                  <label for="telefono">Precio buscado por el cliente:</label>
                </div>
              </div>
              <div className="col-md-3 col-sm-12 form-inline">
                <label for="itv" style={{ fontSize: "13px" }}>
                  ITV {""}
                </label>
                <input
                  style={{ fontSize: "13px" }}
                  setter={"itv"}
                  id="itv"
                  placeholder={"Última ITV"}
                  className={`form-control ds-input mt-1 ${styles.floatingInput}`}
                  type="date"
                  value={lead?.itv}
                  onChange={(e) => handleValues(e, "itv", "lead")}
                />
              </div>
              <div className="col-md-3 col-sm-12">
                <Checkbox
                  key={"Importado"}
                  name={"¿Importado?"}
                  status={lead?.importado}
                  onChange={() =>
                    getData_CheckBox(!lead.importado, "importado")
                  }
                />
              </div>
            </div>
          </div>{" "}
          <div className="row">
            <div className="col-md-12 col-sm-12">
              <textarea
                type="textarea"
                placeholder={"Comentarios..."}
                className="form-control ds-input mt-1"
                value={lead?.comentarios}
                onChange={(e) => handleValues(e, "comentarios", "lead")}
              />
            </div>
          </div>
        </div>

        <div className={`m-3 row fixed-bottom ${styles.navMov}`}>
          <Button
            color="success"
            className={styles.create}
            onClick={() => createLead()}
          >
            <MaterialIcon icon={"save"} />
          </Button>
        </div>
      </Container>
    </>
  );
};

export default FormLead;
