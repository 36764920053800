import React from "react";
import { Label, Input } from "reactstrap";

export default ({ onChange, status, name }) => {
  return (
    <div>
      <Label style={{ marginLeft: "30px" }} check>
        <Input type="checkbox" onChange={onChange} checked={status} />
        {name}
      </Label>
    </div>
  );
};
