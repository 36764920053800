import React from 'react';
import DatosVehiculos from '../../DatosVehiculos';
import FormLead from '../../FormLead';

const LeadsCompra = () => {
    return (
  
            <FormLead/>
      
    );
};

export default LeadsCompra;