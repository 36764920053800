import { create } from "./BaseService";

const http = create({
  baseURL: process.env.REACT_APP_API_HOST || `http://localhost:3002`,
  maxContentLength: Infinity,
  maxBodyLength: Infinity,
  timeout: 25000,
  useAccessToken: false,
  reloadOnUnauthorized: false,
})

export const login = (body) => {
  return http.post('/user', body)
}




