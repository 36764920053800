import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
export const warningToast = (text) =>
  toast.warn(text, {
    position: "bottom-right",
    autoClose: 8000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });

export const errorToast = (text) =>{
  toast.error(text, {
    position: "bottom-right",
    autoClose: 4000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
}
export const successToast = (text) =>
  toast.success(text, {
    position: "bottom-right",
    autoClose: 8000,
    hideProgressBar: false,
    closeOnClick: false,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
